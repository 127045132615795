<template>
  <div class="user-result">
	<div id="canvas" v-if="resultInfo!==''">
		<ul class="baby-name-list" v-if="resultInfo.list !==undefind">
			<li v-for="(item, index) in resultInfo.list" :key="index">
				{{item.name}}
			</li>
		</ul>
	</div>
	<div class="loading-items" id="loading-items" v-if="resultInfo === ''">
		<div class="loading">
			<div class="loading_mask"></div>
			<div class="weui-loadmore">
			<div class="weui-loading"></div>
			<div class="weui-loadmore__tips">测算结果正在加载中 大约5-7秒 请不要退出~</div>
			</div>
		</div>
	</div>
	<div class="loading-items" id="loading-items" v-if="isConver">
      <div class="loading">
        <div class="loading_mask"></div>
        <div class="weui-loadmore">
        	<div class="weui-loading"></div>
        	<div class="weui-loadmore__tips">结果图片生成中，请不要退出~</div>
      	</div>
	  </div>
	</div>
	<div class="button-items" v-if="resultInfo !== '' && resultInfo.list !== undefind">
      <div class="notice">
        为保护您的隐私 系统不记录测算结果
      </div>
      <input type="button" :disabled="isConver ? true : false" value="点击生成图片" @click="saveImg" />
    </div>
	<div class="dialog-tip" v-if="showDialog">
		<div class="content">
		<p>为了保护您的隐私，系统不记录您的测算结果，浏览后请点击下方按钮生成图片，长按保存到相册，以免退出后丢失。</p>
		<div class="btn">
			<input type="button" value="我知道了" @click="gotEvent" />
		</div>
		</div>
	</div>
	<div class="dialog-tip" v-if="showError">
		<div class="content">
			<p>很抱歉，该姓氏暂时未录入，请联系客服为您退款。</p>
			<div class="btn">
				<input type="button" value="我知道了" @click="goHomeEvent" />
			</div>
		</div>
	</div>
  </div>
</template>
<style src="./style.css"></style>
<script>
import html2canvas from 'html2canvas'
import { getBBQM } from '../api/index'
import wx from 'weixin-js-sdk'
  export default{
	data() {
	  return {
		resultInfo: '',
		imagePhoto: '',
		height: 0,
		form: {},
		isConver: false,
		showDialog: false,
		showError:false
	  }
	},
	created() {
	  const params = {
		username: this.$route.query.username,
		gender:this.$route.query.gender,
		date: this.$route.query.date,
		hour: Number(this.$route.query.hour),
		count: Number(this.$route.query.count),
	  }
	  this.form = params
	},
	mounted() {
	  if(this.form.username) {
		this.initData(this.form)
	 }

	},
	methods: {	
	  async initData(params) {
		const res = await getBBQM(params)
		if(res.data) {
			//console.log(res.data)
			this.resultInfo =  res.data
			setTimeout(() => {
			   this.showDialog = true
			},500)
			return false
		} 
		if (res.code === 1001) {
		  //console.log(res.msg)
		  this.resultInfo = {};
		  this.showError = true
			//   wx.showToast({ title: '很抱歉,该姓氏暂时未录入,请联系客服为您退款!', icon: 'none'})
			//   setTimeout(() => {
			// 	wx.redirectTo({
			// 		url:"/pages/index/index",
			// 	});
			//   },2000)
		}
	  },
	  goHomeEvent() {
		this.showError = false
		var miniprogram;
		  const isWechatMiniProgram = /miniProgram/i.test(navigator.userAgent.toLowerCase());
		  const isWechatBrowser = /MicroMessenger/i.test(navigator.userAgent);
		  if (isWechatMiniProgram) {
			  miniprogram = "miniprogram1"
		  } else if (isWechatBrowser) {
			  miniprogram = "wechatBrowser"
		  } else {
			 miniprogram =  "normal"
		  }	
      try {  
			if (miniprogram === 'miniprogram1') {
				wx.miniProgram.reLaunch({url: `/pages/index/index`})
			}
		} catch (e) {
				//console.log(wx, '不在微信开发者环境');
		}
	  },
	  taskScreenshort(dom) {
		//const dom = document.getElementById("canvas")
		const width = dom.offsetWidth
		this.height = dom.offsetHeight
		let scale = 2 // 设定倍数这样不会模糊
		return new Promise((resolve,reject) => {
			html2canvas(dom, {
				allowTaint: true, //允许被污染
				width: width,
				heigth: this.height,
				windowHeight: this.height,
				windowWidth: width,
				scale: scale,
				scrollX: 0,
				scrollY: 0,
				y: 0,
				x: -10,
				useCORS: true
			}).then(canvas => {
				resolve(canvas)
			}).catch(err => {
				reject(err)
			})
		})
      },
	  saveImg() {
		//alert(document.getElementById("canvas"))
		this.isConver = true
		this.taskScreenshort(document.getElementById("canvas")).then(canvas => {
			this.imagePhoto = canvas.toDataURL('image/png', 1.0) //将图片转为base64
			//alert(this.imagePhoto)
			localStorage.setItem('base64', this.imagePhoto)
			//console.log(this.imagePhoto)
			this.isConver = false
			window.location.href= `#/showImg?height=${this.height}`;
			this.showDialog = false
		})
	  },
	  gotEvent() {
		//console.log(this.showDialog)
		this.showDialog = false
	  }
	}
  }
</script>