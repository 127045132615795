<template>
  <div>
    <div id="canvas" class="user-result" v-if="resultInfo !== ''">
        <div class="sex-box">
            <div bindtap="addEvent" data-type='girl'>
                <div class="icon">
                    <img src="https://dekangzhijia.com/static/icon-4.png" />
                </div>
                <div class="content">
                    <h1>{{ form.girl_username }}</h1>
                    <div>{{ form.girl_date }}</div>
                    <div> {{ form.girl_hour + ':00 - ' +  form.girl_hour + ':59'  }}</div>
                </div>
            </div>
            <div class="icon-1">
                <img src="https://dekangzhijia.com/static/icon-1.png" /> 
            </div>
            <div bindtap="addEvent" data-type='boy'>
                <div class="icon icon-boy">
                    <img src="https://dekangzhijia.com/static/icon-5.png" />
                </div>
                <div class="content">
                    <h1>{{ form.username }}</h1>
                    <div>{{ form.date }}</div>
                    <div>{{ form.hour + ':00 - ' +  form.hour + ':59'  }}</div>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="title" style="text-align:left;padding-left:20rpx;">{{form.girl_username}}命格</div>
            <div class="item">
                <div>
                    <h1 class="tit">生肖:</h1>
                    <p>{{resultInfo.shengxiao2}}</p> 
                </div>
                <div>
                    <h1  class="tit">命宫: </h1>
                    <p>{{resultInfo.m_v}}</p>
                </div>
                <div>
                    <h1  class="tit">十神: </h1>
                    <p>{{resultInfo.shishen1}} | {{resultInfo.shishen2}}</p>
                </div>
                <div>
                    <h1  class="tit">坤造: </h1>
                    <p>{{resultInfo.qianzao2}}</p>
                </div>
                <div>
                    <h1  class="tit">支十神: </h1>
                    <p>{{resultInfo.zhishishen2}}</p>
                </div>
                <div>
                    <h1  class="tit">四柱十神:</h1>
                    <p> {{resultInfo.shishen_for2}}</p>
                </div>
            </div>
            <div class="title" style="text-align:left;padding-left:20rpx;">{{form.username}}命格</div>
            <div class="item">
                <div>
                  <text class="tit">生肖: </text>
                  <p>{{resultInfo.shengxiao1}}</p>
                </div>
                <div>
                    <text  class="tit">命宫: </text>
                    <p>{{resultInfo.m_na}}</p>
                </div>
                <div>
                    <text  class="tit">十神:</text>
                    <p> {{resultInfo.shishen1}} | {{resultInfo.shishen2}}</p>
                </div>
                <div>
                    <text  class="tit">乾造: </text>
                    <p>{{resultInfo.qianzao1}}</p>
                </div>
                <div>
                    <text  class="tit">支十神: </text>
                    <p>{{resultInfo.zhishishen1}}</p>
                </div>
                <div>
                    <text  class="tit">四柱十神: </text>
                    <p>{{resultInfo.shishen_for1}}</p>
                </div>
            </div>
            <div class="title">适配总分</div>
            <div class="item">
              <p>{{resultInfo.zongfen}}</p>
            </div>
            <div class="title">生肖婚配吉凶</div>
            <div class="item">
              <p>{{resultInfo.haar1}}</p>
            </div>
            <div class="title">五行婚配吉凶</div>
            <div class="item">
              <p>{{resultInfo.haar2}}</p>
            </div>
            <div class="title">婚姻宫婚配吉凶</div>
            <div class="item">
              <p>{{resultInfo.haar3}}</p>
            </div>
            <div class="title">子女宫婚配吉凶</div>
            <div class="item">
              <p>{{resultInfo.haar4}}</p>
            </div>
        </div>
    </div>
    <div class="loading-items" id="loading-items" v-if="resultInfo === ''">
      <div class="loading">
        <div class="loading_mask"></div>
        <div class="weui-loadmore">
          <div class="weui-loading"></div>
          <div class="weui-loadmore__tips">测算结果正在加载中 大约5-7秒 请不要退出~</div>
        </div>
      </div>
      <div class="loading-items" id="loading-items" v-if="isConver">
        <div class="loading">
          <div class="loading_mask"></div>
          <div class="weui-loadmore">
            <div class="weui-loading"></div>
            <div class="weui-loadmore__tips">结果图片生成中，请不要退出~</div>
          </div>
        </div>
      </div>
    </div>
    <div class="button-items" v-if="resultInfo !== ''">
      <div class="notice">
        为保护您的隐私 系统不记录测算结果
      </div>
      <input type="button" :disabled="isConver ? true : false" value="点击生成图片" @click="saveImg" />
    </div>
    <div class="dialog-tip" v-if="showDialog">
      <div class="content">
        <p>为了保护您的隐私，系统不记录您的测算结果，浏览后请点击下方按钮生成图片，长按保存到相册，以免退出后丢失。</p>
        <div class="btn">
          <input type="button" value="我知道了" @click="gotEvent" />
        </div>
      </div>
    </div>
  </div>
</template>
<style src="./style.css"></style>
<script>
  import html2canvas from 'html2canvas'
  import { getHehunData } from '../api/index'
  export default{
    data() {
      return {
        resultInfo: '',
        imagePhoto: '',
        height: 0,
        form: {},
        isConver: false,
        showDialog: false
      }
    },
    mounted() {
      //console.log(this.$route.query)
        //&date=${that.data.params.date}&hour=${that.data.params.hour}&girl_username=${that.data.params.girl_username}
        //&girl_date=${that.data.params.girl_date}&girl_hour=${that.data.params.girl_hour}
      const params = {
        username: this.$route.query.username,
        date:this.$route.query.date,
        hour: Number(this.$route.query.hour),
        girl_username:this.$route.query.girl_username,
        girl_date:this.$route.query.girl_date,
        girl_hour:Number(this.$route.query.girl_hour),
      }
      this.form = params
      console.log(params)
      if(params.username) {
        this.initData(params)
      }
    },
    methods: {
      async initData(params) {
        const res = await getHehunData(params)
        if(res.data) {
          // console.log(res.data)
          this.resultInfo =  res.data
          setTimeout(() => {
            this.showDialog = true
          },500)
        }
      },
      taskScreenshort(dom) {
        //const dom = document.getElementById("canvas")
        const width = dom.offsetWidth
        this.height = dom.offsetHeight
        let scale = 2 // 设定倍数这样不会模糊
        return new Promise((resolve,reject) => {
          html2canvas(dom, {
            allowTaint: true, //允许被污染
            width: width,
            heigth: this.height,
            windowHeight: this.height,
            windowWidth: width,
            scale: scale,
            scrollX: 0,
            scrollY: 0,
            y: 0,
            x: -10,
            useCORS: true
          }).then(canvas => {
            resolve(canvas)
          }).catch(err => {
            reject(err)
          })
        })
      },
      saveImg() {
        //alert(document.getElementById("canvas"))
        this.isConver = true
        this.taskScreenshort(document.getElementById("canvas")).then(canvas => {
          this.imagePhoto = canvas.toDataURL('image/png', 1.0) //将图片转为base64
          localStorage.setItem('base64', this.imagePhoto)
          //console.log(this.imagePhoto)
          this.isConver = false
          window.location.href= `#/showImg?height=${this.height}`;
          this.showDialog = false
        })
      },
      gotEvent() {
        this.showDialog = false
      }
    }
  }
</script>