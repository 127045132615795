import axios from "axios";
import qs from "qs";
const service = axios.create({
  baseURL: "/",
  timeout: 300000,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    //"Access-Control-Allow-Origin":"*",
    "Access-Control-Allow-Origin":"https://dekangzhijia.com"
  }
});
/*
 *请求前拦截
 *用于处理需要请求前的操作
 */
service.interceptors.request.use(
  config => {
    let token = localStorage.getItem("av-token");
    //配置请求头
    if (token) {
      config.headers["token"] = token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
/*
 *请求响应拦截
 *用于处理数据返回后的操作
 */
service.interceptors.response.use(
  response => {
    return new Promise(resolve => {
      const res = response.data;
      resolve(res);
    });
  },
  error => {
    //console.log(error);
    // 断网处理或者请求超时
    if (!error.response) {
      console.log(error);
      // 请求超时
      if (error.message.includes("timeout")) {
        console.log("超时了");
        //messages("error", "请求超时，请检查互联网连接");
      } else {
        // 断网，可以展示断网组件
        console.log("断网了");
      }
      return;
    }
    const status = error.response.status;
    switch (status) {
      case 401:
      case 500:
        //messages("error", "服务器内部错误");

        break;
      case 404:
        //messages("error", "未找到远程服务器");
        break;
      default:
      //messages("error", error.response.data.message);
    }
    return Promise.reject(error);
  }
);

/*
 *get方法，对应get请求
 *@param {String} url [请求的url地址]
 *@param {Object} params [请求时候携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    service
      .get(url, {
        params
      })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}
/*
 *post方法，对应post请求
 *@param {String} url [请求的url地址]
 *@param {Object} params [请求时候携带的参数]
 */
export function post(url, params) {
  return new Promise((resolve, reject) => {
    service
      .post(url, params)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

/*
 *以query String格式, 发送post请求
 *@param {String} url [请求的url地址]sessionStorage.setItem("av-token", resp.data);
 *@param {Object} params [请求时候携带的参数]
 */
export function postQS(url, params) {
  return new Promise((resolve, reject) => {
    service
      .post(url, qs.stringify(params), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}
