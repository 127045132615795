<template>
  <div class="img-items">
    <div class="title">已生成图片，请长按保存或分享</div>
    <img :src="src" class="image" :style="{height: height + 'px'}" />
  </div>
</template>
<script>
import wx from 'weixin-js-sdk'
export default {
  data() {
    return {
      src: "",
      height: 0
    }
  },
  created() {
    this.src = localStorage.getItem("base64")
    this.height = this.$route.query.height
  },
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  unmounted() {
    localStorage.removeItem("base64")
      var miniprogram;
		  const isWechatMiniProgram = /miniProgram/i.test(navigator.userAgent.toLowerCase());
		  const isWechatBrowser = /MicroMessenger/i.test(navigator.userAgent);
		  if (isWechatMiniProgram) {
			  miniprogram = "miniprogram1"
		  } else if (isWechatBrowser) {
			  miniprogram = "wechatBrowser"
		  } else {
			 miniprogram =  "normal"
		  }	
      try {  
				if (miniprogram === 'miniprogram1') {
          wx.miniProgram.reLaunch({url: `/pages/index/index`})
        }
			} catch (e) {
				//console.log(wx, '不在微信开发者环境');
			}
  }
}
</script>
<style scoped>
  .title{
    color: green;
    font-size: 15px;
    padding: 10px 0;
    text-align: center;
    font-weight: bold;
  }
  .img-items{
    padding: 30px;
    background: rgb(235,236,238);
  }
  .img-items > .image{
    width: 100%;
  }
</style>