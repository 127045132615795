<template>
  <div class="c-list">
    <div class="title">
       <input v-model="name"/>
       <button class="button" @click="search">查询</button>
    </div>
    <ul class="list">
      <li v-for="(item, index) in data" :key="index" @click="gotoEvent(index,item)">
        <div class="img">
          <img src="https://dekangzhijia.com/static/temp_ico.jpg" />
        </div>
        <div class="content">
            <h1>{{item.title}}</h1>
            <p>价格：{{item.price}}</p>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
  export default{
    data(){
      return {
        name: '',
        initData:[
          { title: '资本思维翻转财富', url: 'https://dekangzhijia.com/class/class_01.jpg', price: '199' },
          { title: '108个税收实操法宝', url: 'https://dekangzhijia.com/class/class_02.jpg', price: '199'  },
          { title: '财税管理二合一', url: 'https://dekangzhijia.com/class/class_03.jpg', price: '299'  },
          { title: '零基础全面入门英语', url: 'https://dekangzhijia.com/class/class_04.jpg', price: '99'  },
          { title: '引爆业绩.实战谈判与回款技巧', url: 'https://dekangzhijia.com/class/class_05.jpg', price: '599'  },
          { title: '管理者的领导力+团队激励36计', url: 'https://dekangzhijia.com/class/class_06.jpg', price: '599'  },
          { title: '痕迹识人之管理篇：把握人性，管理随心', url: 'https://dekangzhijia.com/class/class_07.jpg', price: '399'  },
          { title: '营销特种兵：10步成交潜在客户', url: 'https://dekangzhijia.com/class/class_08.jpg', price: '399'  },
          { title: '管理随心私房课', url: 'https://dekangzhijia.com/class/class_09.jpg', price: '399'  },
          { title: '高手秘笈-赢在学习规划', url: 'https://dekangzhijia.com/class/class_10.jpg', price: '399'  },
          { title: '人间清醒后半生', url: 'https://dekangzhijia.com/class/class_11.jpg', price: '399'  },
          { title: '颠覆传统-英文学习法', url: 'https://dekangzhijia.com/class/class_12.jpg', price: '399'  },
          { title: '用营销逆向破局', url: 'https://dekangzhijia.com/class/class_13.jpg', price: '399'  },
          { title: '管理高手实战课-管理不用人盯人', url: 'https://dekangzhijia.com/class/class_14.jpg', price: '399'  },
          { title: '人效提升五步法-人力资源管理实操课', url: 'https://dekangzhijia.com/class/class_15.jpg', price: '399'  },
          { title: '流程性思维课', url: 'https://dekangzhijia.com/class/class_16.jpg', price: '399'  },
          { title: '管理者领导力课-教你解决团队沟通和激励难题', url: 'https://dekangzhijia.com/class/class_17.jpg', price: '399'  },
          { title: '核心英语1000词', url: 'https://dekangzhijia.com/class/class_18.jpg', price: '399'  },
          { title: '痕迹识人之面试篇', url: 'https://dekangzhijia.com/class/class_19.jpg', price: '299'  },
          { title: '痕迹识人之沟通篇-职场管理通用', url: 'https://dekangzhijia.com/class/class_20.jpg', price: '299'  }
        ],
        data:[
          { title: '资本思维翻转财富', url: 'https://dekangzhijia.com/class/class_01.jpg', price: '199' },
          { title: '108个税收实操法宝', url: 'https://dekangzhijia.com/class/class_02.jpg', price: '199'  },
          { title: '财税管理二合一', url: 'https://dekangzhijia.com/class/class_03.jpg', price: '299'  },
          { title: '零基础全面入门英语', url: 'https://dekangzhijia.com/class/class_04.jpg', price: '99'  },
          { title: '引爆业绩.实战谈判与回款技巧', url: 'https://dekangzhijia.com/class/class_05.jpg', price: '599'  },
          { title: '管理者的领导力+团队激励36计', url: 'https://dekangzhijia.com/class/class_06.jpg', price: '599'  },
          { title: '痕迹识人之管理篇：把握人性，管理随心', url: 'https://dekangzhijia.com/class/class_07.jpg', price: '399'  },
          { title: '营销特种兵：10步成交潜在客户', url: 'https://dekangzhijia.com/class/class_08.jpg', price: '399'  },
          { title: '管理随心私房课', url: 'https://dekangzhijia.com/class/class_09.jpg', price: '399'  },
          { title: '高手秘笈-赢在学习规划', url: 'https://dekangzhijia.com/class/class_10.jpg', price: '399'  },
          { title: '人间清醒后半生', url: 'https://dekangzhijia.com/class/class_11.jpg', price: '399'  },
          { title: '颠覆传统-英文学习法', url: 'https://dekangzhijia.com/class/class_12.jpg', price: '399'  },
          { title: '用营销逆向破局', url: 'https://dekangzhijia.com/class/class_13.jpg', price: '399'  },
          { title: '管理高手实战课-管理不用人盯人', url: 'https://dekangzhijia.com/class/class_14.jpg', price: '399'  },
          { title: '人效提升五步法-人力资源管理实操课', url: 'https://dekangzhijia.com/class/class_15.jpg', price: '399'  },
          { title: '流程性思维课', url: 'https://dekangzhijia.com/class/class_16.jpg', price: '399'  },
          { title: '管理者领导力课-教你解决团队沟通和激励难题', url: 'https://dekangzhijia.com/class/class_17.jpg', price: '399'  },
          { title: '核心英语1000词', url: 'https://dekangzhijia.com/class/class_18.jpg', price: '399'  },
          { title: '痕迹识人之面试篇', url: 'https://dekangzhijia.com/class/class_19.jpg', price: '299'  },
          { title: '痕迹识人之沟通篇-职场管理通用', url: 'https://dekangzhijia.com/class/class_20.jpg', price: '299'  }
        ]
      }
    },
    methods: {
      search() {

        if (this.name === '') {
          this.data = this.initData;
          //console.log(this.data)
          return false
        }
        const item = this.data.filter(item => {
          if (item.title.includes(this.name)) {
            return item
          }
        })
        this.data =[...item]
      },
      gotoEvent(index,row) {
        this.$router.push({path: '/class_content',query: {index: index  + 1, title: row.title,price: row.price}})
      }
    }
  }
</script>

<style scoped>
  .c-list{
    background:#f4f4f4;
  }
  .c-list .title{
    background: #F7B336;
    text-align: center;
    padding: 10px;
    display: flex;
  }
  .c-list .title > input{
    width:80%;
    height:40px;
    border:1px solid transparent;
    border-radius: 4px;
    text-indent: inherit;
    font-size: 16px;
    outline: none;
  }
  .c-list .title > .button{
    background: #3CB371;
    width: 80px;
    text-align: center;
    height: 40px;
    margin-left: 10px;
    border-radius: 4px;
    border:none;
    font-size: 16px;
    color: #fff;
    font-weight: bold;
  }
  .c-list .list {
    padding:10px;
    
  }
  .c-list .list  > li{
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    background: #fff;
    padding: 10px;
  }
  .c-list .list .img{
    border:1px solid #ccc;
    height: 100px;
    width:50%;
    flex-shrink: 0;
    text-align: center;
  }
  .c-list .list .img > img{
    max-width: 100%;
    height: 100%;
  }
  .c-list .list .content{
    padding-left:20px;
    position: relative;
    height:100px;
    width: 80%;
  }
  .c-list .list .content > h1{
    font-size: 16px;
    font-weight: bold;
  }
  .c-list .list .content >  p{
    font-size:24px;
    position: absolute;
    bottom:0;
    left:20px;
    color:#f00;
    font-weight: bold;
  }
</style>